import React from "react";
import Contact from "../../components/Contact";

const ContactPage = () => {
  return (
    <section className="flex flex-col w-full min-h-[100vh] gap-6 pt-24">
      <div className="flex flex-col px-6 md:px-24 pb-12">
        <h1 className="text-text-second-gray text-4xl md:text-6xl font-bold text-center">
          Contact Us
        </h1>
        <Contact />
      </div>
    </section>
  );
};

export default ContactPage;
