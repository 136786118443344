import React, { useContext, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { stylesConfig } from "../../../helpers/stylesConfig";
import MenuItem from "./MenuItem";
import LanguageSettings from "../../components/LanguageSettings";
import MenuMobile from "../../../components/MenuMobile";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";
import { ContactScrollContext } from "../../../store/triggerScrollToContact";
import { COMMON_ALT } from "../../../constant";

const MenuTop = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);

  const location = useLocation();
  const navigate = useNavigate();

  const isHomePage = useMemo(() => {
    if (location.pathname === "/") {
      return true;
    } else {
      return false;
    }
  }, [location.pathname]);

  const scrollCtx = useContext(ContactScrollContext);
  return (
    <div className="flex justify-between items-center">
      <MenuMobile />
      <Link to="/">
        <img
          alt={COMMON_ALT}
          src={stylesConfig.mobileLogo}
          className="w-[30px] cursor-pointer ml-[16px] mobile_show"
        />
        <img
          alt={COMMON_ALT}
          src={stylesConfig.mainLogo}
          className="w-[100px] cursor-pointer mr-[16px] logo-drop-shadow pc_show"
        />
      </Link>
      <MenuItem />
      <div className="flex justify-between items-center">
        <div
          onClick={() => {
            if (isHomePage) {
              scrollCtx.trigger();
            } else {
              navigate("/");
              scrollCtx.trigger();
            }
          }}
          className="text-white cursor-pointer text-xs md:text-base font-bold blue-button bg-orange-button hover:bg-orange-button-hover px-3 md:px-5 py-1.5 md:py-2.5 rounded-[12px] hover-bg-transition"
        >
          {i18n("Contact")}
        </div>
        <section className="pc_show">
          <LanguageSettings />
        </section>
      </div>
    </div>
  );
};

export default MenuTop;
