import React, { forwardRef, useRef } from "react";
import WrapperTitle from "./WrapperTitle";
import { BiSolidPhone } from "react-icons/bi";
import { BsPersonFill, BsFillBuildingFill } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { Button, Form, message, Input, Space } from "antd";
import SpecText from "./SpecText";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";

const Contact = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lv5v4a1",
        "template_frsan7q",
        form.current,
        "CUOsBqNSzl_dSYuQo"
      )
      .then(
        (result) => {
          console.log(result.text);
          message.success(i18n("SUCCESS!"));
        },
        (error) => {
          console.log(error.text);
          message.error(i18n("FAILED..."));
        }
      );
  };

  return (
    <section ref={ref} className="relative">
      <WrapperTitle icon={<BiSolidPhone />} title={i18n("Contact Us")} />
      <div className="bg-base-white menu-box-shadow px-6 py-4 mt-3 rounded-xl flex flex-col md:flex-row justify-between items-center gap-x-10 gap-y-4">
        <div className="flex flex-col flex-1 gap-3.5">
          <h1 className="text-light-black text-2xl md:text-4xl font-bold">
            {i18n("Get our exclusive offer!")}
          </h1>
          <ul className="flex flex-col gap-2 pl-5 md:pl-10">
            <li className="text-text-second-gray text-base font-normal py-1.5 li-dot">
              <SpecText> {i18n("Seamless API")} </SpecText>
              {i18n("integration")}.
            </li>
            <li className="text-text-second-gray text-base font-normal py-1.5 li-dot">
              {i18n("Supports multiple")}
              <SpecText> {i18n("currencies")} </SpecText>
              {i18n("and")}
              <SpecText> {i18n("languages")}</SpecText>.
            </li>
            <li className="text-text-second-gray text-base font-normal py-1.5 li-dot">
              <SpecText>{i18n("Responsive")} </SpecText>
              {i18n("on most devices")}.
            </li>
            <li className="text-text-second-gray text-base font-normal py-1.5 li-dot">
              {i18n("Optimized for")}
              <SpecText> {i18n("low-end devices")} </SpecText>
              {i18n("with")}
              <SpecText> {i18n("low bandwidth")} </SpecText>
              {i18n("usage")}.
            </li>
          </ul>
        </div>
        <form className="flex-1" ref={form} onSubmit={sendEmail}>
          <div className="flex flex-col gap-3">
            <div className="w-full flex justify-between gap-2">
              <div className="inline-flex flex-col relative w-full">
                <div>
                  <Input
                    type="text"
                    name="name"
                    className="inline-flex items-center input-border cursor-text rounded-xl p-3 text-light-gray bg-white"
                    size="large"
                    placeholder={i18n("Your Name")}
                    prefix={
                      <BsPersonFill className="text-input-icon text-2xl mr-2" />
                    }
                  />
                </div>
              </div>
              <div className="inline-flex flex-col relative w-full">
                <div>
                  <Input
                    type="text"
                    name="company"
                    className="inline-flex items-center input-border cursor-text rounded-xl p-3 text-light-gray bg-white"
                    size="large"
                    placeholder={i18n("Company")}
                    prefix={
                      <BsFillBuildingFill className="text-input-icon text-2xl mr-2" />
                    }
                  />
                </div>
              </div>
            </div>
            <div className="inline-flex flex-col relative w-full">
              <div>
                <Input
                  type="email"
                  name="email"
                  className="inline-flex items-center input-border cursor-text rounded-xl p-3 text-light-gray bg-white"
                  size="large"
                  placeholder={i18n("Email")}
                  prefix={<IoMail className="text-input-icon text-2xl mr-2" />}
                />
              </div>
            </div>
            <div className="inline-flex flex-col relative w-full">
              <div>
                <Input
                  type="text"
                  name="phone"
                  className="inline-flex items-center input-border cursor-text rounded-xl p-3 text-light-gray bg-white"
                  size="large"
                  placeholder={i18n("Phone")}
                  prefix={
                    <BiSolidPhone className="text-input-icon text-2xl mr-2" />
                  }
                />
              </div>
            </div>
            <Button
              type="submit"
              htmlType="submit"
              className="more-button rounded-[5px] px-[10px] py-[5px] h-auto"
            >
              {i18n("Submit")}
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
});

export default Contact;
