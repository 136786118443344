import React from "react";
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, message, Space } from "antd";
import {
  IoHome,
  IoGameController,
  IoPlanet,
  IoTrophy,
  IoChevronForwardOutline,
  IoChevronDownOutline,
} from "react-icons/io5";
import { TbPokerChip } from "react-icons/tb";
import { SiBlockchaindotcom } from "react-icons/si";
import { FaFish } from "react-icons/fa";
import { PiCertificateFill } from "react-icons/pi";
import { BsShieldFillCheck } from "react-icons/bs";
import { MdCasino } from "react-icons/md";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";

const MenuItem = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);
  const itemLinks = [
    { to: "/", text: i18n("Home"), icon: <IoHome /> },
    //{ to: "/products", text: i18n("Products"), icon: <IoGameController /> },
  ];

  const productItems = [
    {
      label: (
        <Link to="/products" className="text-base font-bold">
          {i18n("All Games")}
        </Link>
      ),
      key: "0",
      icon: <IoGameController className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link to="/products/Slot" className="text-base font-bold">
          {i18n("Slot")}
        </Link>
      ),
      key: "1",
      icon: <TbPokerChip className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link to="/products/Blockchain" className="text-base font-bold">
          {i18n("Blockchain")}
        </Link>
      ),
      key: "4",
      icon: <SiBlockchaindotcom className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link to="/products/SICBO" className="text-base font-bold">
          {i18n("SICBO")}
        </Link>
      ),
      key: "3",
      icon: <MdCasino className="mr-2 !text-2xl" />,
      size: "large",
    },
    // {
    //   label: (
    //     <Link to="/products/Fish" className="text-base font-bold">
    //       {i18n("Fish")}
    //     </Link>
    //   ),
    //   key: "2",
    //   icon: <FaFish className="mr-2 !text-2xl" />,
    //   size: "large",
    // },
  ];
  const companyItems = [
    {
      label: (
        <Link to="/company/about" className="text-base font-bold">
          {i18n("About Us")}
        </Link>
      ),
      key: "1",
      icon: <IoTrophy className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link to="/company/provablyFair" className="text-base font-bold">
          {i18n("Provably Fair")}
        </Link>
      ),
      key: "1",
      icon: <BsShieldFillCheck className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link to="/company/licenses" className="text-base font-bold">
          {i18n("Licenses")}
        </Link>
      ),
      key: "2",
      icon: <PiCertificateFill className="mr-2 !text-2xl" />,
      size: "large",
    },
  ];

  return (
    <section className="gap-[10px] justify-between items-center hidden md:flex">
      <div className="flex gap-[10px]">
        {itemLinks.map((item, index) => {
          return (
            <Link
              to={item.to}
              className="flex items-center menu-font font-bold text-text-second-gray hover:text-main-color p-3 rounded-[5px] hover:bg-hover-mask-bg hover-bg-transition"
            >
              <span className="mr-2 text-2xl">{item.icon}</span> {item.text}
            </Link>
          );
        })}
        <Dropdown
          size="large"
          menu={{ items: productItems }}
          className="cursor-pointer"
          trigger={["hover"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space className="menu-font font-bold text-text-second-gray hover:text-main-color p-3 rounded-[5px] hover:bg-hover-mask-bg hover-bg-transition">
              <IoGameController className="mr-2 text-2xl" />
              {i18n("Products")}
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
        <Dropdown
          size="large"
          menu={{ items: companyItems }}
          className="cursor-pointer"
          trigger={["hover"]}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space className="menu-font font-bold text-text-second-gray hover:text-main-color p-3 rounded-[5px] hover:bg-hover-mask-bg hover-bg-transition">
              <IoPlanet className="mr-2 text-2xl" />
              {i18n("Company")}
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      </div>
    </section>
  );
};

export default MenuItem;
