import React, { useState } from "react";
import { Link } from "react-router-dom";
import { stylesConfig } from "../helpers/stylesConfig";
import MenuItem from "../pages/components/MenuBar/MenuItem";
import LanguageSettings from "../pages/components/LanguageSettings";
import { HiMenu } from "react-icons/hi";
import MenuContent from "./MenuContent";
import { motion, AnimatePresence } from "framer-motion";

const MenuMobile = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <AnimatePresence>
      <div>
        <motion.div
          className="mobile_show bg-white hover:bg-menu-background rounded-lg p-2"
          onClick={toggleMenu}
        >
          <HiMenu className="text-text-second-gray text-base cursor-pointer" />
        </motion.div>
        {isMenuOpen && <MenuContent onClose={toggleMenu} />}
        {/* 傳遞 onClose 属性 */}
      </div>
    </AnimatePresence>
  );
};

export default MenuMobile;
