import React from "react";
import { Helmet } from "react-helmet";
import { stylesConfig } from "../../helpers/stylesConfig";

const WebHelmet = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Eazy Gaming</title>
      <meta
        name="description"
        content="Eazy Gaming was created by experienced and passionate industry experts, and we develop top-notch slot games for the global audience. We also provide fishing games, offering a rich variety of gaming options for online entertainment operators."
      />
      <meta content="website" property="og:type" />
      <meta
        property="og:title"
        content="Eazy Gaming was created by experienced and passionate industry experts, and we develop top-notch slot games for the global audience. We also provide fishing games, offering a rich variety of gaming options for online entertainment operators."
      />
      <meta
        property="og:description"
        content="Eazy Gaming was created by experienced and passionate industry experts, and we develop top-notch slot games for the global audience. We also provide fishing games, offering a rich variety of gaming options for online entertainment operators."
      />
      <meta property="og:image" content={stylesConfig.mainLogo} />
      <meta property="og:url" content="https://egslot.net/" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=0"
      ></meta>
      <link rel="icon" href={stylesConfig.mobileLogo} />
    </Helmet>
  );
};

export default WebHelmet;
