import React from "react";
import { Link } from "react-router-dom";
import {
  IoHome,
  IoGameController,
  IoPlanet,
  IoTrophy,
  IoChevronForwardOutline,
  IoChevronDownOutline,
} from "react-icons/io5";
import { PiCertificateFill } from "react-icons/pi";
import { LuExternalLink } from "react-icons/lu";
import { BsShieldFillCheck } from "react-icons/bs";
import license from "../images/icon/licenses.webp";
import provablyFair from "../images/icon/provably-fair.webp";
import tournament from "../images/icon/tournaments.webp";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Feature = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const featureData = [
    {
      icon: <PiCertificateFill className="text-white text-4xl" />,
      imageSrc: license,
      title: i18n("Licenses"),
      description: i18n(
        "Play with Assurance Backed by Our Industry's Most Respected Certifications"
      ),
      link: "/company/licenses",
    },
    {
      icon: <IoTrophy className="text-white text-4xl" />,
      imageSrc: tournament,
      title: i18n("About Us"),
      description: i18n(
        "Eazy Gaming was created by experienced and passionate industry experts"
      ),
      link: "/company/about",
    },
    {
      icon: <BsShieldFillCheck className="text-white text-4xl" />,
      imageSrc: provablyFair,
      title: i18n("Provably Fair"),
      description: i18n(
        "Our cryptographic technology ensures 100% trust and transparency for players."
      ),
      link: "/company/provablyFair",
    },
  ];

  return (
    <div className="block md:flex gap-3 justify-around items-center">
      {featureData.map((feature, index) => (
        <div
          key={index}
          className="flex flex-col gap-1 items-start md:items-center max-w-full md:max-w-[270px] my-4"
        >
          <div className="flex flex-row md:flex-col w-full gap-4 items-center">
            <div className="feature-icon relative flex items-center justify-center w-[64px] h-[64px]">
              {feature.icon}
              <LazyLoadImage
                className="absolute"
                src={feature.imageSrc}
                alt={feature.title}
                width="80"
              />
            </div>
            <div className="w-full md:w-fit flex justify-between items-center">
              <h2 className="text-text-second-gray font-semibold">
                {feature.title}
              </h2>
              <Link to={feature.link} className="flex md:hidden">
                <LuExternalLink className="text-light-gray text-xl" />
              </Link>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <span className="text-sm font-normal text-light-gray text-center">
              {feature.description}
            </span>
            <Link to={feature.link} className="hidden md:flex">
              <div className="learn-button my-2">{i18n("Learn More")}</div>
            </Link>
          </div>
        </div>
      ))}
      {/* <div className="flex flex-col gap-1 items-start md:items-center max-w-full md:max-w-[270px]">
        <div className="flex flex-row md:flex-col w-full gap-4 items-center">
          <div className="feature-icon relative flex items-center justify-center w-[64px] h-[64px]">
            <PiCertificateFill className="text-light-gray text-4xl" />
            <img
              className="absolute"
              src="https://cdn.imoon.com/crashdemo/b2b/company/tournaments/icon.png"
              alt=""
              width="80"
            />
          </div>
          <div className="w-full md:w-fit flex justify-between items-center">
            <h2 className="text-text-second-gray font-semibold">Licenses</h2>
            <Link to="/products" className="flex md:hidden">
              <LuExternalLink className="text-light-gray text-xl" />
            </Link>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <span className="text-base font-normal text-light-gray text-center">
            Play with Assurance Backed by Our Industry's Most Respected
            Certifications
          </span>
          <Link to="" className="hidden md:flex">
            <div className="learn-button my-2">Learn More</div>
          </Link>
        </div>
      </div> */}
    </div>
  );
};

export default Feature;
