import React from "react";
import { Link } from "react-router-dom";
import { stylesConfig } from "../helpers/stylesConfig";
import introImg from "../images/game/B2C.webp";
import SpecText from "./SpecText";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";

const IntroBlock = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  return (
    <section className="bg-linear flex flex-col md:flex-row rounded-xl items-center">
      <div className="flex flex-col flex-1 items-end justify-start gap-4 p-6">
        <div className="flex justify-center w-full text-center">
          <img
            src={stylesConfig.mainLogo}
            className="w-[150px] text-center logo-drop-shadow"
          />
        </div>
        <div className="flex flex-col w-full gap-3">
          <h1 className="text-light-black text-2xl md:text-4xl font-bold">
            {i18n("UNRIVALLED GAMING EXPERIENCE")}
          </h1>
          <h4 className="text-light-gray text-sm md:text-base font-bold">
            {i18n(
              "The industry want great games, and we have a ready-made advantage."
            )}{" "}
            {/* <SpecText>Eazy Gaming</SpecText> */}
          </h4>
          <h4 className="text-light-gray text-sm md:text-base font-bold">
            {i18n(
              "Embrace top-tier game design and innovative technology in the industry, and immerse yourself in an unparalleled gaming adventure!"
            )}{" "}
          </h4>
        </div>
        {/* <Link
          to="/"
          className="flex justify-center items-center self-center md:self-start"
        >
          <div class="more-button rounded-[5px] px-[10px] py-[5px]">
            {i18n("Go to Website")}
          </div>
        </Link> */}
      </div>
      <div className="flex flex-col flex-1 items-end justify-end rounded-2xl overflow-hidden">
        <LazyLoadImage src={introImg} className="w-full max-w-[415px]" />
      </div>
    </section>
  );
};

export default IntroBlock;
