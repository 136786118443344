import React from "react";
import { IconContext } from "react-icons";
import { HiMiniRocketLaunch } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";

const WrapperTitle = ({ title, icon }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  return (
    <div className="flex items-center">
      <span className="text-main-color text-xl mr-2"> {icon}</span>
      <h2 className="text-text-second-gray font-extrabold text-lg">{title}</h2>
    </div>
  );
};

export default WrapperTitle;
