import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { gameData } from "../../helpers/gamelist";
import Iframe from "./components/iframe";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import i18n from "../../../src/i18n/i18n";
import GameInfo from "./components/gameInfo";

const { Option } = Select;

function PlayGame() {
  const { t } = useTranslation();
  const i18n = (key) => t(`page.${key}`);

  const { gameUid } = useParams(); //useParams是一個hook，他的作用是獲取路由參數
  const [selectedLanguage, setSelectedLanguage] = useState("en"); // 預設語言是英文

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value); //把value傳進去selectedLanguage
    console.log(`selected ${value}`);
  };

  const gameConfig = useMemo(() => {
    //useMemo是一個hook，他的作用是為了避免重複渲染，當gameUid改變的時候，才會重新渲染
    return gameData.find((game) => {
      //find是一個方法，他的作用是找到gameData裡面的game，並且把他返回出來
      return game.uid == gameUid; //如果gameData裡面的game的uid等於gameUid，就返回出來
    });
  }, [gameUid]);

  const {
    //Demolink = "",
    type = "",
    title = "",
    direction = "",
    intro = "",
    maxOdds = "",
    RTP = "",
    Resolution = "",
  } = gameConfig;
  //意思是如果gameConfig里面没有{}裡面這些属性，就给他一个空字符串

  const Demolink = `${gameConfig.Demolink}&lang=${selectedLanguage}&curr=usd&useIFrame=true`;
  //這個是demoLink的組件，他的作用是把gameConfig裡面的Demolink和selectedLanguage這個props傳進去

  //根據不同的遊戲，顯示不同的語言選項
  const renderLanguageOptions = () => {
    const commonOptions = [
      { value: "en", label: "English" },
      { value: "zh-chs", label: "简体中文" },
      { value: "zh-cht", label: "繁體中文" },
      { value: "pt", label: "Português" },
    ];

    const allOptions = [
      ...commonOptions,
      { value: "ja", label: "日本語" },
      { value: "ko", label: "한국어" },
      { value: "id", label: "Bahasa Indonesia" },
      { value: "es", label: "Español" },
      { value: "vi", label: "Tiếng Việt" },
    ];

    if (type === "Blockchain") {
      allOptions.push({ value: "th", label: "แบบไทย" });
    }

    return title === "Fortune Piggy Coin" ? commonOptions : allOptions; //如果遊戲title是Fortune Piggy Coin，就顯示commonOptions，否則顯示allOptions
  };

  return (
    <section className="flex flex-col w-full min-h-[100vh] gap-6 px-6 md:px-24 py-10 pt-24">
      <Iframe Demolink={Demolink} direction={direction} />
      {/*這個是iframe的組件 傳入了Demolink和direction的props */}
      <div className="mt-2">
        <h1 className="text-light-black text-2xl md:text-4xl font-bold">
          {i18n(title)}
        </h1>
        <div className="text-light-gray font-normal my-2">{i18n(intro)}</div>
      </div>
      <div></div>
      <section className="w-full max-w-[500px]">
        <GameInfo />
        <div className="grid grid-cols-2 items-center text-light-gray text-base font-medium border-b border-light-gray py-3">
          <span>{i18n("Language")}</span>
          <span>
            <Select
              value={selectedLanguage}
              style={{ width: 180 }}
              onChange={handleLanguageChange}
            >
              {renderLanguageOptions().map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </span>
        </div>
      </section>
      {/* <section className="w-full max-w-[500px]">
        {infoItems.map((item, index) => (
          <div
            key={index}
            className="grid grid-cols-2 items-center text-light-gray text-base font-medium border-b border-light-gray py-3"
          >
            <span>{i18n(item.label)}</span>
            <span>{item.value}</span>
          </div>
        ))}
      </section> */}
    </section>
  );
}
export default PlayGame;
