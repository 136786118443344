import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, message, Space } from "antd";
import {
  IoHome,
  IoGameController,
  IoPlanet,
  IoTrophy,
  IoChevronForwardOutline,
  IoChevronDownOutline,
} from "react-icons/io5";
import { TbPokerChip } from "react-icons/tb";
import { SiBlockchaindotcom } from "react-icons/si";
import { FaFish } from "react-icons/fa";
import { PiCertificateFill } from "react-icons/pi";
import { BsShieldFillCheck } from "react-icons/bs";
import { MdCasino } from "react-icons/md";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";

const MenuContentInner = ({ onClose }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`header.${key}`);
  //const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false);
  // 管理各自Dropdown的展開/摺疊狀態

  const itemLinks = [
    { to: "/", text: i18n("Home"), icon: <IoHome /> },
    //{ to: "/products", text: i18n("Products"), icon: <IoGameController /> },
  ];

  const companyItems = [
    {
      label: (
        <Link
          to="/company/about"
          onClick={onClose}
          className="text-base font-bold"
        >
          {i18n("About Us")}
        </Link>
      ),
      key: "1",
      icon: <IoTrophy className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link
          to="/company/provablyFair"
          onClick={onClose}
          className="text-base font-bold"
        >
          {i18n("Provably Fair")}
        </Link>
      ),
      key: "1",
      icon: <BsShieldFillCheck className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link
          to="/company/licenses"
          onClick={onClose}
          className="text-base font-bold"
        >
          {i18n("Licenses")}
        </Link>
      ),
      key: "2",
      icon: <PiCertificateFill className="mr-2 !text-2xl" />,
      size: "large",
    },
  ];
  const productItems = [
    {
      label: (
        <Link to="/products" onClick={onClose} className="text-base font-bold">
          {i18n("All Games")}
        </Link>
      ),
      key: "0",
      icon: <IoGameController className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link
          to="/products/Slot"
          onClick={onClose}
          className="text-base font-bold"
        >
          {i18n("Slot")}
        </Link>
      ),
      key: "1",
      icon: <TbPokerChip className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link
          to="/products/Blockchain"
          onClick={onClose}
          className="text-base font-bold"
        >
          {i18n("Blockchain")}
        </Link>
      ),
      key: "4",
      icon: <SiBlockchaindotcom className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link
          to="/products/SICBO"
          onClick={onClose}
          className="text-base font-bold"
        >
          {i18n("SICBO")}
        </Link>
      ),
      key: "3",
      icon: <MdCasino className="mr-2 !text-2xl" />,
      size: "large",
    },
    // {
    //   label: (
    //     <Link
    //       to="/products/Fish"
    //       onClick={onClose}
    //       className="text-base font-bold"
    //     >
    //       {i18n("Fish")}
    //     </Link>
    //   ),
    //   key: "2",
    //   icon: <FaFish className="mr-2 !text-2xl" />,
    //   size: "large",
    // },
  ];
  return (
    <div className="flex flex-col py-4 justify-between items-center md:hidden">
      {itemLinks.map((item, index) => {
        return (
          <Link
            to={item.to}
            onClick={onClose}
            className="flex items-center menu-font w-full font-bold text-text-second-gray hover:text-main-color p-3 py-4 rounded-[5px] hover:bg-hover-mask-bg hover-bg-transition"
          >
            <span className="mr-2 text-2xl">{item.icon}</span> {item.text}
          </Link>
        );
      })}
      <Dropdown
        size="large"
        menu={{ items: productItems }}
        className="w-full z-[99999]"
        onOpenChange={(visible) => setIsProductDropdownOpen(visible)}
        onClose={onClose}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space className="menu-font font-bold w-full text-text-second-gray hover:text-main-color p-3 py-4 rounded-[5px] hover:bg-hover-mask-bg hover-bg-transition">
            <IoGameController className="mr-2 text-2xl" />
            {i18n("Products")}
            {isProductDropdownOpen ? (
              <IoChevronDownOutline className="mr-2 text-2xl" />
            ) : (
              <IoChevronForwardOutline className="mr-2 text-2xl" />
            )}
          </Space>
        </a>
      </Dropdown>
      <Dropdown
        size="large"
        menu={{ items: companyItems }}
        className="w-full z-[99999]"
        onOpenChange={(visible) => setIsCompanyDropdownOpen(visible)}
        onClose={onClose}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space className="menu-font font-bold w-full text-text-second-gray hover:text-main-color p-3 py-4 rounded-[5px] hover:bg-hover-mask-bg hover-bg-transition">
            <IoPlanet className="mr-2 text-2xl" />
            {i18n("Company")}
            {isCompanyDropdownOpen ? (
              <IoChevronDownOutline className="mr-2 text-2xl" />
            ) : (
              <IoChevronForwardOutline className="mr-2 text-2xl" />
            )}
          </Space>
        </a>
      </Dropdown>
    </div>
  );
};

export default MenuContentInner;
