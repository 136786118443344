import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { gameData } from "../../helpers/gamelist";
import { Button, Form, Input } from "antd";
import { BsSearch } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import i18n from "../../../src/i18n/i18n";
import { useParams } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, message, Space } from "antd";
import {
  IoHome,
  IoGameController,
  IoPlanet,
  IoTrophy,
  IoChevronForwardOutline,
  IoChevronDownOutline,
} from "react-icons/io5";
import { TbPokerChip } from "react-icons/tb";
import { SiBlockchaindotcom } from "react-icons/si";
import { FaFish } from "react-icons/fa";
import { PiCertificateFill } from "react-icons/pi";
import { BsShieldFillCheck } from "react-icons/bs";
import { MdCasino } from "react-icons/md";
import NewGameTag from "../../components/NewGameTag";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Products = ({ game }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`page.${key}`);
  const [searchTerm, setSearchTerm] = useState(""); //這個state用來存搜索條件 預設為空

  const { type } = useParams();
  console.log("type:", type);

  const GAME_LIST_FILTER = useMemo(() => {
    // 根据type过滤游戏数据
    const filteredGames = gameData.filter((game) => {
      if (type) {
        return game.type === type;
      } else {
        return true; // 如果没有type参数，返回所有游戏数据
      }
    });

    // 使用搜索条件再次过滤游戏数据
    return filteredGames.filter((game) =>
      game.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, type, gameData]);

  const productItems = [
    {
      label: (
        <Link to="/products" className="text-base font-bold">
          {i18n("All Games")}
        </Link>
      ),
      key: "0",
      icon: <IoGameController className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link to="/products/Slot" className="text-base font-bold">
          {i18n("Slot")}
        </Link>
      ),
      key: "1",
      icon: <TbPokerChip className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link to="/products/Blockchain" className="text-base font-bold">
          {i18n("Blockchain")}
        </Link>
      ),
      key: "4",
      icon: <SiBlockchaindotcom className="mr-2 !text-2xl" />,
      size: "large",
    },
    {
      label: (
        <Link to="/products/SICBO" className="text-base font-bold">
          {i18n("SICBO")}
        </Link>
      ),
      key: "3",
      icon: <MdCasino className="mr-2 !text-2xl" />,
      size: "large",
    },
    // {
    //   label: (
    //     <Link to="/products/Fish" className="text-base font-bold">
    //       {i18n("Fish")}
    //     </Link>
    //   ),
    //   key: "2",
    //   icon: <FaFish className="mr-2 !text-2xl" />,
    //   size: "large",
    // },
  ];

  return (
    <section className="flex flex-col w-full min-h-[100vh] gap-6 px-2 md:px-24 py-12 pt-24 md:pt-36">
      <div className="flex flex-col">
        <h1 className="text-light-black text-3xl md:text-4xl font-bold text-center">
          {type ? i18n(type) : i18n("All Games")}
        </h1>
        <span className="text-text-second-gray text-xl font-normal text-center mt-1">
          {i18n("Find out more about our games")}
        </span>
      </div>
      <div>
        <div className="flex justify-center items-center">
          <Input
            className="w-[70%] max-w-[500px] input-border cursor-text rounded-xl p-3 text-light-gray bg-menu-background my-6"
            type="text"
            placeholder={i18n("Search Games")}
            size="large"
            value={searchTerm}
            onChange={(e) => {
              // 用onChange監聽input的值，並將值設置為searchTerm
              setSearchTerm(e.target.value);
            }}
            prefix={<BsSearch className="text-input-icon text-2xl mr-2" />}
          />
          <Dropdown
            size="large"
            menu={{ items: productItems }}
            className="cursor-pointer ml-1 md:ml-2"
            trigger={["hover"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space className="menu-font font-bold text-text-second-gray hover:text-main-color p-3 rounded-[5px] hover:bg-hover-mask-bg hover-bg-transition">
                {i18n("Products")}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
        <div className="flex justify-center flex-wrap w-full gap-2">
          {GAME_LIST_FILTER.length === 0 ? (
            <p className="text-light-gray font-semibold text-xl text-center">
              {i18n("No games found")}.
            </p>
          ) : (
            GAME_LIST_FILTER.map((game, index) => (
              <div key={game.title} className="rounded-xl">
                <div className="gameSlide-container">
                  <NewGameTag game={game} />
                  <LazyLoadImage
                    src={game.image}
                    alt={game.title}
                    className="rounded-xl object-cover w-full h-[200px]"
                  />
                  <div className="overlay rounded-xl">
                    <div className="overlay-content">
                      <p>{i18n(game.title)}</p>
                      <Link to={`/play/${game.uid}`}>
                        <div className="play-button rounded-[5px] !px-[8px] !py-[6px] !mb-2 max-w-[130px] !mx-auto">
                          {i18n("Play Demo")}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default Products;
