import React from "react";
import provablyBanner from "../../images/show/provably_banner.webp";
import { useTranslation } from "react-i18next";
import i18n from "../../../src/i18n/i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ProvablyFair = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`page.${key}`);

  return (
    <section className="flex flex-col w-full min-h-[100vh] gap-6 pt-24">
      <LazyLoadImage src={provablyBanner} className="w-full" />
      <div className="flex flex-col px-6 md:px-24 py-12">
        <h1 className="text-light-black text-4xl md:text-6xl font-bold text-center">
          {i18n("Provably Fair")}
        </h1>
        <span className="text-light-gray text-lg font-normal text-center my-10">
          {i18n("provably1")}
        </span>
      </div>
    </section>
  );
};

export default ProvablyFair;
