import React from "react";
import { BiSolidTag } from "react-icons/bi";

const NewGameTag = ({ game }) => {
  return (
    <div>
      {game.new && (
        <div className="new-tag">
          <BiSolidTag className="rotate-[-90deg] text-[55px] text-[#E40A13] absolute top-[-5px] left-[-7px] z-[1]" />
          <div className="absolute top-[0px] left-[0px] gap-[-5px] w-[41px] h-[43px] flex flex-col items-center justify-center font-bold text-white z-[1]">
            <span className="text-xs tracking-tighter">New</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewGameTag;
