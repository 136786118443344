import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { Link } from "react-router-dom";
import { carouselData } from "../helpers/carouselBannerList";
import { gameData } from "../helpers/gamelist";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { COMMON_ALT } from "../constant";

const Carousel = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`carousel.${key}`);
  return (
    <section className="carousel-bg md:px-[50px] relative p-2 md:p-0 flex justify-center">
      <Splide
        className="h-[130vw] sm:h-[31vw] lg:h-[370px] w-full max-w-[1680px] overflow-hidden rounded-xl md:rounded-none sm:menu-box-shadow"
        options={{
          type: "loop",
          perPage: 1,
          gap: 0,
          autoplay: false,
          arrows: true,
        }}
      >
        {carouselData.map((game, index) => (
          <SplideSlide key={index}>
            <LazyLoadImage
              alt={game.title}
              src={game.image}
              data-splide-lazy="path-to-the-image"
              className="object-cover w-full h-[143vw] sm:h-[31vw] lg:h-[370px] relative hidden md:block"
            />
            <LazyLoadImage
              alt={game.title}
              src={game.imageMobile}
              data-splide-lazy="path-to-the-image"
              className="object-cover w-full h-[135vw] sm:h-[31vw] lg:h-[370px] relative block md:hidden rounded-xl"
            />
            <div className="flex flex-col absolute w-full sm:w-[57%] bottom-[58%] sm:bottom-[58%]">
              <div className=" flex flex-col gap-2 items-center">
                <LazyLoadImage
                  src={game.logo}
                  alt={game.title}
                  className="w-[180px]"
                  data-splide-lazy="path-to-the-image"
                />
                <div>
                  <div className="text-center pt-2">
                    <h3 className="text-white font-extrabold text-xl">
                      {game.Headtitle}
                    </h3>
                    <p className="text-white font-normal text-sm">
                      {game.description}
                    </p>
                  </div>
                  <div className="flex justify-center items-center gap-3 pt-3">
                    <Link to={`/play/${game.uid}`}>
                      <div className="play-button rounded-[5px] px-[10px] py-[5px]">
                        {i18n("Play Demo")}
                      </div>
                    </Link>
                    <Link to={game.Morelink}>
                      <div className="more-button rounded-[5px] px-[10px] py-[5px]">
                        {i18n("Read More")}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </section>
  );
};

export default Carousel;
