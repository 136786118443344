export const languageMapping = (language) => {
  switch (language) {
    case "zh_chs":
      return "简体";
    case "en":
      return "English";
  }
};

export const languageOptions = [
  { value: "en", label: "English" },
  { value: "zh_chs", label: "简体中文" },
];
