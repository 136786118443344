import React, { useContext, useEffect, useRef, useState } from "react";
import Carousel from "../../components/Carousel";
import Feature from "../../components/Feature";
import GameCarousel from "../../components/GameCarousel";
import AllGameCarousel from "../../components/AllGameCarousel";
import Footer from "../../components/Footer";
import IntroBlock from "../../components/IntroBlock";
import LevelUp from "../../components/LevelUp";
import Contact from "../../components/Contact";
import { ContactScrollContext } from "../../store/triggerScrollToContact";

const Home = () => {
  const contactRef = useRef(null);

  // const [firstRender, setFirstRender] = useState(true);

  const scrollCtx = useContext(ContactScrollContext);
  useEffect(() => {
    if (scrollCtx.state) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollCtx]);
  return (
    <section className="pt-[56px] md:pt-[71px]">
      <Carousel />
      <div className="flex flex-col w-full gap-6 px-2 md:px-24 py-12 pt-2 md:pt-12">
        <AllGameCarousel type="Slot" />
        <AllGameCarousel type="Blockchain" />
        <AllGameCarousel type="SICBO" />
        {/* <AllGameCarousel type="Fish" /> */}
        <LevelUp promo="Promo" />
        {/* <AllGameCarousel /> */}
        <IntroBlock />
        <Contact ref={contactRef} />
        <Feature />
      </div>
    </section>
  );
};

export default Home;
