import "./App.css";
import Wrapper from "./components/Wrapper";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
  Redirect,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import Products from "./pages/Products/Products";
import Company from "./pages/Company/About";
import Contact from "./components/Contact";
import MenuTop from "./pages/components/MenuBar/MenuTop";
import LanguageSettings from "./pages/components/LanguageSettings";
import Footer from "./components/Footer";
import PlayGame from "./pages/PlayGame/PlayGame";
import Licenses from "./pages/Company/Licenses";
import ProvablyFair from "./pages/Company/ProvablyFair";
import About from "./pages/Company/About";
import ContactPage from "./pages/Contact/ContactPage";
import {
  ContactScrollContext,
  ContactScrollProvider,
} from "./store/triggerScrollToContact";
import { useContext } from "react";
import WebHelmet from "./components/SEO/helmet";

function App() {
  const scrollCtx = useContext(ContactScrollContext);
  return (
    <ContactScrollProvider>
      <WebHelmet />
      <Router>
        <div className="bg-menu-background menu-box-shadow px-4 py-3 fixed top-0 w-full z-10">
          <MenuTop />
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products/:type?" element={<Products />} />
          <Route path="/company" element={<Company />} />
          <Route path="/company/about" element={<About />} />
          <Route path="/company/licenses" element={<Licenses />} />
          <Route path="/company/provablyFair" element={<ProvablyFair />} />
          <Route path="/contactPage" element={<ContactPage />} />
          <Route path="/play/:gameUid" element={<PlayGame />} />
        </Routes>
        <Footer />
      </Router>
    </ContactScrollProvider>
  );
}

export default App;
