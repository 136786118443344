import React, { useRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Link } from "react-router-dom";
import { HiMiniRocketLaunch } from "react-icons/hi2";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { gameData } from "../helpers/gamelist";
import WrapperTitle from "./WrapperTitle";
import { useTranslation } from "react-i18next";
import i18n from "../../src/i18n/i18n";
import { stylesConfig } from "../helpers/stylesConfig";
import { BiSolidTag } from "react-icons/bi";
import NewGameTag from "./NewGameTag";
import LazyImage from "../pages/components/Loading/LazyImage";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { COMMON_ALT } from "../constant";

const AllGameCarousel = ({ tag, type }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const splideRef = useRef(null);

  const slideLeft = () => {
    if (splideRef.current) {
      splideRef.current.go("<"); // 向左滑动一个幻灯片
    }
  };

  const slideRight = () => {
    if (splideRef.current) {
      splideRef.current.go(">"); // 向右滑动一个幻灯片
    }
  };

  return (
    <section className="text-center">
      <div className="flex justify-between items-center mb-1 md:mb-4">
        <WrapperTitle
          icon={<HiMiniRocketLaunch />}
          //title={`${tag || ""} Products`}
          title={type ? `${i18n(type)}` : i18n("Products")} // 通过type来判断是否有type，有的话就显示type，没有的话就显示Products
        />
        <div className="flex flex-row items-center">
          <Link to={`/products/${type}`}>
            <div className="view-all-button mr-2">
              {i18n("View")}
              {type ? ` ${i18n(type)}` : ""}
              {/* // 通过type来判断是否有type，有的话就显示type，没有的话就显示空 */}
            </div>
          </Link>
          <div
            className="rounded-xl splide-arrow-button h-[35px] w-[35px] mr-2 flex justify-center items-center"
            onClick={slideLeft}
          >
            <MdArrowBackIosNew className="text-text-second-gray text-xl p-1" />
          </div>
          <div
            className="rounded-xl splide-arrow-button h-[35px] w-[35px] mr-2 flex justify-center items-center"
            onClick={slideRight}
          >
            <MdArrowForwardIos className="text-text-second-gray text-xl p-1 " />
          </div>
        </div>
      </div>
      <Splide
        ref={splideRef}
        aria-label="New Products"
        className=" w-full overflow-hidden"
        options={{
          type: "slide",
          perPage: 7,
          gap: 15,
          autoplay: false,
          arrows: false,
          drag: "free",
          perMove: 1,
          pagination: false,
          lazyLoad: "nearby",
          dragMinThreshold: {
            mouse: 0,
            touch: 10,
          },
          preloadPages: 7,
          keyboard: false,
          breakpoints: {
            640: {
              perPage: 2,
            },
            768: {
              perPage: 4,
            },
            1024: {
              perPage: 5,
            },
            1280: {
              perPage: 7,
            },
          },
        }}
      >
        {gameData
          .filter((item) => {
            if (type) {
              return item.type === type;
            } else {
              return item;
            }
          })
          .map((game, index) => (
            <SplideSlide key={index} className="rounded-xl">
              <div className="gameSlide-container">
                <NewGameTag game={game} />
                <LazyLoadImage
                  src={game.image}
                  alt={game.title}
                  className="rounded-xl object-cover w-full h-[200px]"
                  data-splide-lazy="path-to-the-image"
                />
                <div className="overlay rounded-xl">
                  <div className="overlay-content">
                    <p>{i18n(game.title)}</p>
                    <Link to={`/play/${game.uid}`}>
                      <div className="play-button rounded-[5px] !px-[8px] !py-[6px] !mb-2 max-w-[130px] !mx-auto">
                        {i18n("Play Demo")}
                      </div>
                    </Link>
                    <Link to={game.Morelink}>
                      <div className="more-button rounded-[5px] !px-[8px] !py-[6px] max-w-[130px] !mx-auto">
                        {i18n("Read More")}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </SplideSlide>
          ))}
        <SplideSlide className="rounded-xl">
          <div className="gameSlide-container">
            <div className="bg-base-deep-gray rounded-xl object-cover w-full h-[200px] flex flex-col justify-center items-center">
              <img
                src={stylesConfig.mainLogo}
                alt={COMMON_ALT}
                className="w-[80px] logo-drop-shadow"
                style={{ WebkitFilter: "brightness(1.5)" }}
              />
              <p className="text-light-gray m-2">Coming Soon</p>
            </div>

            <div className="overlay rounded-xl">
              <div className="overlay-content">
                <p>Coming Soon</p>
                <Link to="/products">
                  <div className="more-button rounded-[5px] !px-[8px] !py-[6px]">
                    {i18n("Read More")}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </SplideSlide>
      </Splide>
    </section>
  );
};

export default AllGameCarousel;
